<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style=" min-height: calc(100vh - 82px)"
  >
    <q-card flat bordered style="width:300px;">
      <q-card-section>
        <q-input
          outlined
          readonly
          label="username"
          :value="user.username"
        ></q-input>
      </q-card-section>
      <q-card-section class="column q-col-gutter-sm">
        <q-input outlined stack-label label="Nama User" v-model="newname">
        </q-input>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          flat
          class="full-width  q-mt-lg"
          label="back"
          color="primary"
          @click="$router.go(-1)"
        ></q-btn>
        <q-btn
          outline
          class="full-width q-mb-sm"
          label="Edit Password"
          color="primary"
          @click="$router.push(`/pengaturaneditpassword`)"
        ></q-btn>

        <q-btn
          unelevated
          class="full-width"
          color="negative"
          label="Save"
          :disable="user.nama == newname"
          @click="edit"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      newname: "",
      user: {
        username: "",
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getUser();
    this.$q.loading.hide();
  },
  methods: {
    async getUser() {
      let resp = await this.$http.get("/user_data", this.MGetHeader());

      let user = await this.$http.get(
        `/pengaturan/tahun_ajaran/getdata/${resp.data.id}`,
        this.$hlp.getHeader()
      );
      this.user = user.data;
      this.newname = this.user.nama;
    },
    async edit() {
      let data = {
        id: this.user.id,
        name: this.newname,
      };
      await this.$http.put(`/pengaturan/user/updatedata`, data);
      await this.getUser();
      this.$q.notif({
        message: "Nama User Berhasil diganti",
        color: "positive",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
